.ant-pagination {
	direction: ltr; /* Antd class to make rtl the pagination */
}
.ant-table-body {
	scrollbar-width: auto;
	scrollbar-color: auto;
}

.ant-table-body::-webkit-scrollbar {
	width: 6px;
}

.ant-table-body::-webkit-scrollbar-track {
	background-color: #e1e6e6;
}

.ant-table-body::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background-color: #888;
	cursor: pointer;
}
