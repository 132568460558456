.login-form--label label{
    width: 80px;
}
.login-form--btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ant-form-item-required{
    width: 70px;
    font-size: large;
    line-height: 1rem;
    font-weight: 700;
}