.headerContent-container {
	width: 100%;
	height: 60px;
	border-radius: 8px;
	margin-top: 20px;
	box-shadow: 0 0 0.375rem 0.25rem rgba(145, 152, 159, 0.15);
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
}
.search {
	width: 250px;
	direction: rtl;
	padding-right: 20px;
}
.search-placeholder ::placeholder {
	font-size: 12px;
}
