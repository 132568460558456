.custom-modal .ant-modal-footer {
  display: flex;
  justify-content: center;
}

.custom-date-picker {
  width: 100%;
}
.rmdp-container{
  width: 100%;
}
.custom-date-picker .rmdp-input {
  width: 100%;
  padding: 15px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 14px;
  
}

.custom-date-picker .rmdp-wrapper {
  width: 100%;
}
.custom-date-picker .rmdp-input::placeholder {
  color: #d4d4d4;
  opacity: 1;
}
